import axios from 'axios'
const ax = axios.create({
    baseURL: process.env.VUE_APP_URL_PREFIX,
})

if (ax === null) {
    throw new Error('Could not initiate axios for API connection!')
}

export default ax
