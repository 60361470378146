
import ReconnectingWebSocket from 'reconnecting-websocket'
import Vue from 'vue'

export default Vue.extend({
  name: 'App',
  data: () => ({
    kills: [] as any[],
    tops: [] as any[],
    stats: {},
    wss: null as any
  }),
  created() {
    this.wss = new ReconnectingWebSocket(process.env.VUE_APP_URL_PREFIX_WSS)
    this.wss.onmessage = (event: any) => {
      const data = JSON.parse(event.data)
      data.new = true
      this.kills.unshift(data)
    }
  },
  mounted() {
    this.$http.get('api/stats').then((response) => {
      this.stats = response.data
    })
    this.$http.get('api/tops').then((response) => {
      this.tops = response.data
    })
    this.$http.get('api/kills').then((response) => {
      this.kills = response.data
    })
  },
  methods: {
    getShipImage: function (shipId: any, size = 256) {
      return `https://images.evetech.net/types/${shipId}/render?size=${size}`
    },
    getCharImage: function (charId: any) {
      return `https://images.evetech.net/characters/${charId}/portrait`
    },
    getCorpImage: function (corpId: any) {
      return `https://images.evetech.net/corporations/${corpId}/logo`
    },
    getAlliImage: function (alliId: any) {
      return `https://images.evetech.net/alliances/${alliId}/logo`
    },
    formatPrice(price: any) {
      if (isNaN(price)) {
        return 0
      }
      return parseFloat(price).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') ?? 0
    },
    openZkill(killId: any) {
      window.open(`https://zkillboard.com/kill/${killId}/`, '_blank')
    },
    beautifyPrice(price: any) {
      let floatPrice = parseFloat(price)
      if (floatPrice > 1000000000) {
        return (floatPrice / 1000000000).toFixed(2) + 'b'
      } else if (floatPrice > 1000000) {
        return (floatPrice / 1000000).toFixed(2) + 'm'
      } else {
        return floatPrice.toFixed(2)
      }
    }
  }
})
